const ModelHino700 = () => {
  return (
    <div class="container">
      <div className="box-content-page table-scroll">
        <table className="table-box">
          <tbody>
            <tr>
              <th colspan="7">Основное</th>
            </tr>

            <tr>
              <td className="td-left">ФОТО</td>

              <td colspan="6" className="td-right-colspan td-right">
                <img
                  src="https://hino-trucks.kz/wp-content/uploads/2020/02/xzu600-1.jpg"
                  style={{ height: "100px" }}
                />
              </td>
            </tr>

            <tr>
              <td className="td-left">Модификация</td>

              <td colspan="6" className="td-right-colspan td-right">
                XZU650L-HKMMSW3
              </td>
            </tr>

            <tr>
              <td className="td-left">Колесная формула</td>

              <td colspan="6" className="td-right-colspan td-right">
                4x2
              </td>
            </tr>

            <tr>
              <td className="td-left">Тип кабины</td>

              <td colspan="6" className="td-right-colspan td-right">
                Узкая
              </td>
            </tr>

            <tr>
              <td className="td-left">Кол-во рядов</td>

              <td colspan="6" className="td-right-colspan td-right">
                1
              </td>
            </tr>

            <tr>
              <td className="td-left">Кол-во мест для сидений</td>

              <td colspan="6" className="td-right-colspan td-right">
                3
              </td>
            </tr>

            <tr>
              <th colspan="7">Массы, (кг)</th>
            </tr>

            <tr>
              <td className="td-left">
                Максимальная допустимая масса транспортного средства
              </td>

              <td colspan="6" className="td-right-colspan td-right">
                5 500
              </td>
            </tr>

            <tr>
              <td className="td-left">Снаряженная масса шасси</td>

              <td className="td-right td-white-bg">2 135</td>

              <td className="td-right td-white-bg">2 175</td>

              <td className="td-right td-white-bg">2 220</td>
            </tr>

            <tr>
              <td className="td-left">Грузоподъемность шасси</td>

              <td className="td-right td-white-bg">3 365</td>

              <td className="td-right td-white-bg">3 325</td>

              <td className="td-right td-white-bg">3 300</td>
            </tr>

            <tr>
              <td className="td-left">
                Технически допустимая нагрузка на переднюю ось
              </td>

              <td colspan="6" className="td-right-colspan td-right">
                2090
              </td>
            </tr>

            <tr>
              <td className="td-left">
                Технически допустимая нагрузка на заднюю ось
              </td>

              <td colspan="6" className="td-right-colspan td-right">
                3410
              </td>
            </tr>

            <tr>
              <th colspan="7">Габаритные размеры шасси, (мм)</th>
            </tr>

            <tr>
              <td className="td-left">Длина</td>

              <td className="td-right td-white-bg">4 700</td>

              <td className="td-right td-white-bg">5 185</td>

              <td className="td-right td-white-bg">5 965</td>
            </tr>

            <tr>
              <td className="td-left">Ширина</td>

              <td colspan="6" className="td-right-colspan td-right">
                1 860
              </td>
            </tr>

            <tr>
              <td className="td-left">Высота</td>

              <td className="td-right td-white-bg">2 105</td>

              <td className="td-right td-white-bg">2 100</td>

              <td className="td-right td-white-bg">2 100</td>
            </tr>

            <tr>
              <td className="td-left">Колесная база</td>

              <td className="td-right td-white-bg">2 525</td>

              <td className="td-right td-white-bg">2 800</td>

              <td className="td-right td-white-bg">3 400</td>
            </tr>

            <tr>
              <td className="td-left">Задний свес</td>

              <td className="td-right td-white-bg">1 130</td>

              <td className="td-right td-white-bg">1 370</td>

              <td className="td-right td-white-bg">1 550</td>
            </tr>

            <tr>
              <td className="td-left">Дорожный просвет по заднему мосту</td>

              <td colspan="6" className="td-right-colspan td-right">
                160
              </td>
            </tr>

            <tr>
              <td className="td-left">Колея передних колес</td>

              <td colspan="6" className="td-right-colspan td-right">
                1 400
              </td>
            </tr>

            <tr>
              <td className="td-left">Колея задних колес</td>

              <td colspan="6" className="td-right-colspan td-right">
                1 435
              </td>
            </tr>

            <tr>
              <td className="td-left">Радиус разворота по колесам</td>

              <td className="td-right td-white-bg">5 500</td>

              <td className="td-right td-white-bg">6 100</td>

              <td className="td-right td-white-bg">7 200</td>
            </tr>

            <tr>
              <td className="td-left">Радиус разворота от стенки до стенки</td>

              <td className="td-right td-white-bg">6 000</td>

              <td className="td-right td-white-bg">6 600</td>

              <td className="td-right td-white-bg">7 700</td>
            </tr>

            <tr>
              <th colspan="7">Двигатель</th>
            </tr>

            <tr>
              <td className="td-left">Модель</td>

              <td colspan="6" className="td-right-colspan td-right">
                N04C-UV
              </td>
            </tr>

            <tr>
              <td className="td-left">Экологический класс</td>

              <td colspan="6" className="td-right-colspan td-right">
                Евро-5
              </td>
            </tr>

            <tr>
              <td className="td-left">Тип</td>

              <td colspan="6" className="td-right-colspan td-right">
                Дизельный, 4-х тактный, рядный, 4-х цилиндровый, рабочий объем 4
                009 см3, турбонаддув с промежуточным охлаждением, система
                рециркуляции отработавших газов, каталитический нейтрализатор
                отработавших газов, фильтр очистки твёрдых частиц, система
                питания Сommon-rail (DENSO)
              </td>
            </tr>

            <tr>
              <td className="td-left">
                Максимальная мощность, (кВт (л.с.) / об/мин)
              </td>

              <td colspan="6" className="td-right-colspan td-right">
                110 (150) / 2 500
              </td>
            </tr>

            <tr>
              <td className="td-left">
                Максимальный крутящий момент, (Н∙м / об/мин)
              </td>

              <td colspan="6" className="td-right-colspan td-right">
                420 / 1 400
              </td>
            </tr>

            <tr>
              <th colspan="7">Трансмиссия</th>
            </tr>

            <tr>
              <td className="td-left">Модель</td>

              <td colspan="6" className="td-right-colspan td-right">
                AISIN M550
              </td>
            </tr>

            <tr>
              <td className="td-left">Тип</td>

              <td colspan="6" className="td-right-colspan td-right">
                Механическая, 5-ступенчатая, полностью синхронизированная
              </td>
            </tr>

            <tr>
              <td className="td-left">1-я передача</td>

              <td colspan="6" className="td-right-colspan td-right">
                4,981
              </td>
            </tr>

            <tr>
              <td className="td-left">2-я передача</td>

              <td colspan="6" className="td-right-colspan td-right">
                2,911
              </td>
            </tr>

            <tr>
              <td className="td-left">3-я передача</td>

              <td colspan="6" className="td-right-colspan td-right">
                1,556
              </td>
            </tr>

            <tr>
              <td className="td-left">4-я передача</td>

              <td colspan="6" className="td-right-colspan td-right">
                1,000
              </td>
            </tr>

            <tr>
              <td className="td-left">5-я передача</td>

              <td colspan="6" className="td-right-colspan td-right">
                0,738
              </td>
            </tr>

            <tr>
              <td className="td-left">6-я передача</td>

              <td colspan="6" className="td-right-colspan td-right">
                -
              </td>
            </tr>

            <tr>
              <td className="td-left">Задняя передача</td>

              <td colspan="6" className="td-right-colspan td-right">
                4,625
              </td>
            </tr>

            <tr>
              <td className="td-left">Передаточное число главной передачи</td>

              <td colspan="6" className="td-right-colspan td-right">
                4,625
              </td>
            </tr>

            <tr>
              <th colspan="7">Сцепление</th>
            </tr>

            <tr>
              <td className="td-left">Тип</td>

              <td colspan="6" className="td-right-colspan td-right">
                Сухое, однодисковое, диафрагменного типа, гидравлический привод
              </td>
            </tr>

            <tr>
              <th colspan="7">Тормозная система</th>
            </tr>

            <tr>
              <td className="td-left">Тип</td>

              <td colspan="6" className="td-right-colspan td-right">
                Гидравлическая, двухконтурная, вакуумный усилитель, ABS, VSC
                (система курсовой устойчивости)
              </td>
            </tr>

            <tr>
              <td className="td-left">Передние тормоза</td>

              <td colspan="6" className="td-right-colspan td-right">
                Дисковые, вентилируемые
              </td>
            </tr>

            <tr>
              <td className="td-left">Задние тормоза</td>

              <td colspan="6" className="td-right-colspan td-right">
                Дисковые, вентилируемые
              </td>
            </tr>

            <tr>
              <td className="td-left">Стояночный тормоз</td>

              <td colspan="6" className="td-right-colspan td-right">
                Барабанного типа на выходном валу коробки передач, механический
                привод
              </td>
            </tr>

            <tr>
              <td className="td-left">Вспомогательная</td>

              <td colspan="6" className="td-right-colspan td-right">
                Моторный тормоз
              </td>
            </tr>

            <tr>
              <th colspan="7">Топливный бак</th>
            </tr>

            <tr>
              <td className="td-left">Ёмкость (л)</td>

              <td className="td-right td-white-bg">70</td>

              <td className="td-right td-white-bg">70</td>

              <td className="td-right td-white-bg">100</td>
            </tr>

            <tr>
              <th colspan="7">Тип подвески</th>
            </tr>

            <tr>
              <td className="td-left">Передняя</td>

              <td colspan="6" className="td-right-colspan td-right">
                Зависимая, на полуэллиптических листовых рессорах, с
                гидравлическими амортизаторами двойного действия и
                стабилизатором поперечной устойчивости
              </td>
            </tr>

            <tr>
              <td className="td-left">Задняя</td>

              <td colspan="6" className="td-right-colspan td-right">
                Зависимая, с предрессорником, на полуэллиптических листовых
                рессорах, с гидравлическими амортизаторами двойного действия
              </td>
            </tr>

            <tr>
              <th colspan="7">Шины</th>
            </tr>

            <tr>
              <td className="td-left">
                Производитель, модель, размер, индексы нагрузки
              </td>

              <td colspan="6" className="td-right-colspan td-right">
                BRIDGESTONE R202 195/75R16C 110/108N
              </td>
            </tr>

            <tr>
              <th colspan="7">Другие характеристики</th>
            </tr>

            <tr>
              <td className="td-left">Номинальная сила тока генератора, (А)</td>

              <td colspan="6" className="td-right-colspan td-right">
                80
              </td>
            </tr>

            <tr>
              <td className="td-left">Максимальная скорость, (км/ч)</td>

              <td colspan="6" className="td-right-colspan td-right">
                115
              </td>
            </tr>

            <tr>
              <td className="td-left">Максимальный преодолеваемый</td>

              <td colspan="6" className="td-right-colspan td-right">
                60
              </td>
            </tr>

            <tr>
              <td className="td-left">Чертеж шасси на каждую модель</td>

              <td className="td-right td-white-bg">
                <a
                  className="download-table"
                  href="/hino-doc/500/XZU600L-HKMMPW3.pdf"
                  download=""
                >
                  <img src="/wp-content/uploads/2018/06/table-pdf.png" alt="" />
                </a>
              </td>

              <td className="td-right td-white-bg">
                <a
                  className="download-table"
                  href="/hino-doc/500/XZU640L-HKMMPW3.pdf"
                  download=""
                >
                  <img src="/wp-content/uploads/2018/06/table-pdf.png" alt="" />
                </a>
              </td>

              <td className="td-right td-white-bg">
                <a
                  className="download-table"
                  href="/hino-doc/500/XZU650L-HKMMPW3.pdf"
                  download=""
                >
                  <img src="/wp-content/uploads/2018/06/table-pdf.png" alt="" />
                </a>
              </td>
            </tr>

            <tr>
              <th colspan="7">Самосвальный кузов</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModelHino700;
