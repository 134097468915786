import kmuImg from "../assets/images/nadstroyki/kmu.png";
import tentImg from "../assets/images/nadstroyki/tent.png";
import musorovozImg from "../assets/images/nadstroyki/msrvz.png";
import samosvalImg from "../assets/images/nadstroyki/smsvl.png";
import izotermicheskiyImg from "../assets/images/nadstroyki/iztrm.png";
import promtovarImg from "../assets/images/nadstroyki/prmtv.png";
import refrigerratorImg from "../assets/images/nadstroyki/rfrgrt.png";
import cisternImg from "../assets/images/nadstroyki/cstrn.png";
import evacuatorImg from "../assets/images/nadstroyki/evctr.png";
import kmuPicture from "../assets/images/platform-trucks/truck-kmu.jpeg";
import tentPicture from "../assets/images/platform-trucks/truck-tent.jpeg";
import cisternPicture from "../assets/images/platform-trucks/truck-cistern.jpeg";
import evacuatorPicture from "../assets/images/platform-trucks/truck-evktr.jpeg";
import musorovozPicture from "../assets/images/platform-trucks/truck-msrvz.png";
import samosvalPicture from "../assets/images/platform-trucks/truck-smsvl.jpeg";
import refrigerratorPicture from "../assets/images/platform-trucks/truck-rfrgtr.jpeg";
import izotermicheskiyPicture from "../assets/images/platform-trucks/truck-iztrm.jpeg";
import promtovarPicture from "../assets/images/platform-trucks/truck-prmtvr.jpeg";

const platformData = [
  {
    name: "kmu",
    title: "БОРТОВАЯ ПЛАТФОРМА + КМУ",
    image: kmuImg,
    truckImage: kmuPicture,
    text: `Применение КМУ позволяет расширить функциональные возможности
            автомобиля. Такая установка механизирует погрузо-разгрузочные
            операции, снижает количество людей, занятых при их выполнении. Кроме
            того, данное устройство может использоваться при выполнении
            строительных, монтажных и ремонтных работ, осуществления погрузки
            (выгрузки) в ограниченных стесненных условиях.`,
    info: `<p>
            При подборе КМУ с целью установки на бортовой автомобиль необходимо
            знать последующие варианты эксплуатации и, исходя из этих данных,
            выбирать:
          </p>
          <ul>
            <li>длину вылета стрелы (минимальную, максимальную);</li>
            <li>допустимую грузоподъемность на различных вылетах;</li>
            <li>грузовой момент стрелы;</li>
            <li>радиус поворота установки (наибольший, наименьший);</li>
            <li>
              особенности конструкции и порядок выдвижения составляющих стрелы;
            </li>
            <li>предельную высоту подъема груза.</li>
          </ul>`,
  },
  {
    name: "tent",
    title: "БОРТОВАЯ ПЛАТФОРМА + ТЕНТ",
    image: tentImg,
    truckImage: tentPicture,
    text: `Бортовая платформа предназначена для перевозки грузов общего
            назначения Бортовая платформа имеет откидные борта — по два с
            боковых сторон и один сзади. Передний борт установлен стационарно.`,
    info: `<p>
            Наличие двух откидных бортов придает дополнительное удобство при
            погрузочно-разгрузочных работах.
          </p>
          <p>
            Борта могут быть изготовлены из оцинкованной стали или алюминия.
            Откидные борта являются съемными. Каркас платформы сварной,
            усиленный и выполнен из профильных труб. Настил пола — по желанию
            клиента (транспортная фанера, шпунтованная доска, алюминий).
          </p>
          <p>
            Бортовой автомобиль с тентом предназначен для перевозки различных
            грузов с защитой от воздействия окружающей среды. Каркас и тент
            легко устанавливаются и демонтируются. Возможно применение
            механизированной погрузки/разгрузки сзади.
          </p>`,
  },
  {
    name: "musorovoz",
    title: "МУСОРОВОЗ",
    image: musorovozImg,
    truckImage: musorovozImg,
    text: `В городах и посёлках городского типа наиболее востребованной
  коммунальной техникой является мусоровоз. Эта техника ежедневно
  используется коммунальными службами для сбора и перевозки мусора.`,
    info: `<p>
            Именно для таких целей компания HIDRO-MAK разработала
            высокопроизводительный и универсальный мусоровоз с задней загрузкой.
            Задняя загрузка позволяет обеспечить удобный подъезд к площадке с
            контейнерами, кроме того она позволяет осуществлять ручную и
            механическую загрузку нестандартных и крупногабаритных отходов.
          </p>
          <p>
            Мусоровозное оборудование устанавливается на шасси с полной массой
            от 8 до 26 тонн.
          </p>
          <p>
            Данный вид оборудования легко монтируется на автомобили практически
            любого производителя таких как МАЗ, КАМАЗ, FORD, MAN, Scania, Iveco
            и многие другие. Также вы можете купить новый мусоровоз с навесным
            оборудованием HIDRO-MAK в компании АВТЕК или осуществить
            переоборудование ваших мусоровозов в производственном центре АВТЕК,
            который находится в Киеве на улице Пшеничная 9
          </p>
          <p>
            Надёжная низколегированная сталь кузова мусоровоза St 52 устойчивая
            к образивному износу позволяет эксплуатировать мусоровоз в самых
            жестких погодных условиях.
          </p>
          <p>
            Мусоровоз HIDRO-MAK оснащён кантователем контейнеров для выгрузки
            мусора из контейнеров объёмом 120, 240, 360 литров, баков 0,75 и 0,8
            м³ и евроконтейнеров 1,1 м³ в приемный бункер мусоровоза.
          </p>`,
  },
  {
    name: "samosval",
    title: "САМОСВАЛ",
    image: samosvalImg,
    truckImage: samosvalImg,
    text: `Самосвальные платформы с трехсторонней разгрузкой, прежде всего,
            отличаются своей многофункциональностью. Они устанавливаются на
            любые иностранные и отечественные шасси.`,
    info: `<br />
            <p>Особенности самосвалов:</p>
            <p>
              — все самосвалы проходят грузовые испытания с необходимым
              коэффициентом перегруза;
            </p>
            <p>
              — перед покраской платформа и надрамник проходят пескоструйную
              обработку, а также покрываются антикоррозионным грунтом, что
              повышает стойкость металлоконструкции к агрессивным средам.
            </p>
            <p>
              — самосвальная платформа имеет конструкцию, приспособленную для
              установки бортов с нижней и верхней навеской, а также дублированный
              механизм откидывания бортов.
            </p>
            <p>
              — самосвальная платформа имеет конструкцию, приспособленную для
              установки бортов с нижней и верхней навеской, а также дублированный
              механизм откидывания бортов.
            </p>
            <p>
              — самосвалы оборудуются несколькими системами безопасности,
              ограничивающими ход гидроцилиндра.
            </p>
            <p>
              — на приборной панели самосвала установлена свето-звуковая сирена,
              срабатывающая при включении КОМа.
            </p>
            <p>
              — самосвал может быть оснащен крано-манипуляторной установкой, а
              также тентом.
            </p>`,
  },
  {
    name: "furgon-izotermicheskiy",
    title: "ФУРГОН ИЗОТЕРМИЧЕСКИЙ",
    image: izotermicheskiyImg,
    truckImage: izotermicheskiyPicture,
    text: `Фургон изотермический просто незаменим при транспортировке
          продукции, требующей соблюдения особого температурного режима. К
          такой продукции, как правило, относятся:`,
    info: `<br />
          <p>
            — продукты питания;<br />
            — лекарственные препараты.
          </p>
          <p>
            Прежде всего изотермический фургон — это очень жесткая и прочная
            конструкция, обеспечивающая защиту содержимому фургона от внешних
            воздействий. Конструкция автофургона предусматривает возможность
            использования замков, что предотвратит проникновение внутрь фургона.
          </p>
          <p>
            Основой автофургона изотермического является жесткий каркас и
            внутренняя обшивка, выполненная из пищевой оцинкованной стали.
            Внешняя обшивка выполнена из плакированной оцинкованной стали. Для
            теплоизоляции используется пенополистирол, толщина которого
            составляет 50, 80 или 100мм. Все запоры и сам кузов автофургона
            изготовлены из оцинкованной стали.
          </p>`,
  },
  {
    name: "furgon-promtovar",
    title: "ФУРГОН ПРОМТОВАРНЫЙ",
    image: promtovarImg,
    truckImage: promtovarImg,
    text: `<b>ПРОМТОВАРНЫЙ</b> фургон предназначен для перевозки различных
          грузов промышленного назначения, а также продовольственных товаров,
          не требующих особых температурных условий транспортировки. Жесткая
          конструкция фургона обеспечивает защиту от внешнего воздействия, а
          запорная конструкция с возможностью применения замков позволяет
          исключить несанкцированное проникновение внутрь грузового
          пространства.`,
    info: "",
  },
  {
    name: "furgon-refrigerrator",
    title: "ФУРГОН РЕФРИЖЕРАТОР",
    image: refrigerratorImg,
    truckImage: refrigerratorPicture,
    text: `Фургон рефрижератор просто незаменим при транспортировке
          продукции, требующей соблюдения особого температурного режима. К
          такой продукции, как правило, относятся:`,
    info: `<br />
          <p>
            — продукты питания;<br />
            — лекарственные препараты.
          </p>
          <p>
            Фургон рефрижератор отличается наличием холодильных камер. Прежде
            всего фургон рефрижератор — это очень жесткая и прочная конструкция,
            обеспечивающая защиту содержимому фургона от внешних воздействий.
            Конструкция автофургона предусматривает возможность использования
            замков, что предотвратит проникновение внутрь фургона.
          </p>
          <p>
            Основой автофургона рефрижератора является жесткий каркас и
            внутренняя обшивка, выполненная из пищевой оцинкованной стали.
            Внешняя обшивка выполнена из плакированной оцинкованной стали. Для
            теплоизоляции используется пенополистирол, толщина которого
            составляет 50, 80 или 100мм. Все запоры и сам кузов автофургона
            изготовлены из оцинкованной стали.
          </p>`,
  },
  {
    name: "cistern",
    title: "ЦИСТЕРНА",
    image: cisternImg,
    truckImage: cisternImg,
    text: "Автоцистерна предназначена для транспортировки и кратковременного хранения пищевых жидкостей (молоко, вода питьевая), плотностью не более 1,03 г/см³, в условиях предназначенных для изделий изготовленных по категории 1 ГОСТ 15150-69 в климатическом исполнении «У». Исполнение А без средств измерения объема (по ГОСТ 9218).",
    info: "<p>Термоизолированная цистерна состоит из двух секций, основания и отсека обслуживания, расположенного в задней части автоцистерны. Цистерна имеет две горловины, предназначенные для наполнения, дезинфекции и осмотра внутренней поверхности корпуса цистерны. Каждая горловина закрывается термоизолированными крышками. Трубопроводы наполнения-слива имеют эффективную защиту от обмерзания в зимнее время. Цистерна имеет герметичный отсек в котором размещены выводы трубопроводов.</p>",
  },
  {
    name: "evacuator",
    title: "ЭВАКУАТОР СО СДВИЖНОЙ ПЛАТФОРМОЙ",
    image: evacuatorImg,
    truckImage: evacuatorPicture,
    text:
      "Эвакуаторы этого типа оборудованы сдвижной платформой с " +
      "электрической или гидравлической лебедкой. Дополнительно они " +
      "комплектуются подкатными тележками, дополнительными аппарелями, " +
      "комплектом крепежных ремней с натяжными устройствами.",
    info:
      "<p>" +
      "Этот вид прекрасно подходят для транспортировки эвакуатором " +
      "автомобилей с маленьким клиренсом или автоматической коробкой " +
      "передач." +
      "</p>" +
      "<p>" +
      "Основное отличие таких эвакуаторов – это тип платформы, которая " +
      "имеет свойство перемещаться. Передвижение сдвижной платформы " +
      "эвакуатора происходит по средствам работы специальных " +
      "гидроцилиндров. Во время загрузки неисправного транспортного " +
      "средства платформа приподнимается. Это необходимо для достижения " +
      "выгодного наклона во время въезда поврежденного автомобиля, а после " +
      "этого транспортное средство затягивается при помощи лебедки. " +
      "Грузоподъемность эвакуаторов подобного типа, позволяет " +
      "транспортировать не только легковые автомобили, но и микроавтобусы, " +
      "внедорожники. Отличительной чертой этого типа эвакуаторов от " +
      "эвакуаторов с ломаной платформой и лебедкой состоит в отсутствии " +
      "выдвижных аппарелей, это значительно повышается скорость " +
      "погрузочно-разгрузочных работ. Также эвакуаторы этого типа имеют " +
      "малые общие габариты, что очень удобно в большом городе." +
      "</p>",
  },
];

export default platformData;
